
import { mapActions, mapGetters } from 'vuex';

import { AUTH_FO40_LOGIN_SITE, AUTH_LOGIN_SITE } from '@/components/auth/helpers/auth-component-names';

export default {
  name: 'Index',

  components: {
    AuthLogin: () => import('@/components/auth/login/login'),
    AuthLoginSkip: () => import('@/components/auth/login-skip/login-skip'),
    AuthMagicLinkSent: () => import('@/components/auth/magic-link-sent/magic-link-sent'),
    AuthEnterPassword: () => import('@/components/auth/enter-password/enter-password'),
    AuthRequestPasswordReset: () => import('@/components/auth/request-password-reset/request-password-reset'),
    AuthResetPassword: () => import('@/components/auth/reset-password/reset-password'),
    AuthResetPasswordThankYou: () => import('@/components/auth/reset-password-thank-you/reset-password-thank-you'),
    AuthLoginToReverseHealthApp: () =>
      import('@/components/auth/login-to-reverse-health-app/login-to-reverse-health-app'),
    AuthLoginSite: () => import('@/components/auth/login-site/login-site'),
    AuthLoginMagicLinkSent: () => import('@/components/auth/login-magic-link-sent/login-magic-link-sent'),
    AuthFo40LoginSite: () => import('@/components/auth/fo40-login-site/fo40-login-site'),
  },

  layout: 'static',

  fetch() {
    this.$store.dispatch('auth/setAuthComponentName', this.isF040Brand ? AUTH_FO40_LOGIN_SITE : AUTH_LOGIN_SITE);
  },
  head() {
    return {
      title: this.$t('auth.login.meta.title'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('auth.login.meta.description'),
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: this.$t('auth.login.meta.description'),
        },
      ],
    };
  },
  computed: {
    ...mapGetters('auth', ['getAuthComponentName']),
    ...mapActions('auth', ['setAuthComponentName']),
    ...mapGetters('brand', ['getBrand', 'isF040Brand']),
  },
};
